<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogVisibletitle"
    :close-on-click-modal="false"
    width="900px"
  > 
    <template v-if="dialogVisibletitle==='新增用户'||dialogVisibletitle==='修改用户'">
      <div class="dp-f">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          status-icon
          label-width="150px"
          class="demo-ruleForm w100"
        >
          <el-form-item label="用户名" prop='username'>
            <el-input v-model="ruleForm.username" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入用户名"/>
          </el-form-item>
          <el-form-item label="中文名" prop='chnname'>
            <el-input v-model="ruleForm.chnname" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入中文名"/>
          </el-form-item>
          <el-form-item label="密码" prop='password' :class="dialogVisibletitle==='新增用户'?'is-required':''">
            <el-input v-model="ruleForm.password" :maxlength="config.inputMaxlength" show-word-limit type="password" show-password placeholder="请输入密码"/>
          </el-form-item>
          <el-form-item label="部门" prop='deptId'>
            <el-tree-select v-model="ruleForm.deptId" :data="state.deptData" :render-after-expand="false" check-strictly=true placeholder="请选择部门"></el-tree-select>
          </el-form-item>
          <el-form-item label="手机号" prop='phone' class="is-required">
            <el-input v-model="ruleForm.phone" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入手机号"/>
          </el-form-item>
          <el-form-item label="角色" prop='role'>
            <el-select
              v-model="ruleForm.role"
              multiple
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in state.roledata"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="岗位" prop='post'>
            <el-select
              v-model="ruleForm.post"
              multiple
              placeholder="请选择岗位"
            >
              <el-option
                v-for="item in state.postdata"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop='status'>
            <el-radio-group v-model="ruleForm.status">
              <el-radio :label="el.value" size="large" border v-for="(el,ind) in state.statusdata" :key="ind">{{el.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="排序" prop='sort'>
            <el-input-number
              v-model="ruleForm.sort"
              :min="1"
              :max="999"
              controls-position="right"
              :step="1"
            />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='180' height='40' CSStype=3 title="关闭" @buttonclick="dialogVisible = false"></oabutton>
        <oabutton class="searcML" width='180' height='40' :title="dialogVisibletitle==='新增用户'?'添加':'修改'" CSStype=2 @buttonclick="submitForm()" v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
  import { reactive,ref,unref,nextTick,defineEmits } from 'vue'
  import {objdata_ret,objdata_set} from "@/utils/server/format";
  import {handleMessage} from "@/utils/server/confirm";
  import {httpToken} from "@/utils/request";
  import {validPassword,validPhone} from "@/utils/server/validate.js";
  import {getstatusData,getroledata,getpostdata,getdeptData} from "@/utils/server/selectdata";
  import qs from "qs";
  const dialogloading = ref(false);// 弹框按钮loading 显示
  const dialogVisible = ref(false)//弹框显示
  const dialogVisibletitle = ref(false)//弹框标题
  const emit = defineEmits(['Emit'])
  const id = ref(0);//操作的id
  const state = reactive({
    deptData:[],//部门下拉数据
    roledata:[],//角色下拉数据
    postdata:[],//岗位下拉数据
    statusdata:getstatusData(),//状态下拉数据
  })
  getpostdata().then(res=>{state.postdata=res})
  getroledata().then(res=>{state.roledata=res})
  getdeptData().then(res=>{state.deptData=res})
  const ruleFormRef = ref(null);//表单ref
  const ruleForm = reactive({//from 表单数据
    username:'',//用户名
    chnname:'',//中文名
    password:'',//密码
    deptId:'',//所属部门id
    phone:'',//手机号
    role:[],//角色id
    post:[],//岗位id
    status:'0',//状态
    sort:1,//排序
  })
  const validatePass = (rule, value, callback) => {
    if (value === ''||value == null) {
      if(dialogVisibletitle.value==='新增用户'){
        callback(new Error('请输入密码'))
      }else{
        callback()
      }
    } else {
      if(validPassword(value)){
        callback()
      }else 
        callback(new Error('密码格式错误(68-16位数字,字母和特殊字符任意两种组合密码)'))
    }
  }
  const validatePass2 = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请输入手机号'))
    } else {
      if(validPhone(value)){
        callback()
      }else 
        callback(new Error('手机号格式错误'))
    }
  }
  const rules = reactive({//from 表单校验
    username:[
      { required: true, message: '请输入用户名', trigger: 'blur' },
    ],
    password:[
      { validator: validatePass, trigger: 'blur' }
    ],
    chnname:[
      { required: true, message: '请输入中文名', trigger: 'blur' },
    ],
    deptId:[
      { required: true, message: '请选择所属部门', trigger: 'blur' },
    ],
    phone:[
      { validator: validatePass2, trigger: 'blur' }
    ],
    role:[
      { required: true, message: '请选择角色', trigger: 'blur' },
    ],
    post:[
      { required: true, message: '请选择岗位', trigger: 'blur' },
    ],
    status:[
      { required: true, message: '请选择状态', trigger: 'blur' },
    ],
    sort:[
      { required: true, message: '请选择排序', trigger: 'blur' },
    ],
  })
  // 表单重置
  let fromDataReset=(()=>{
    objdata_ret(ruleForm)
    ruleForm.role=[]
    ruleForm.post=[]
    ruleForm.status='0'
    ruleForm.sort=1
  })
  let add=(()=>{
    fromDataReset()
    dialogloading.value=false
    dialogVisibletitle.value='新增用户'
    dialogVisible.value=true
    nextTick(()=>{
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
  let editclick=((row)=>{
    fromDataReset()
    objdata_set(ruleForm,row)
    id.value=row.userId
    dialogloading.value=false
    dialogVisibletitle.value='修改用户'
    dialogVisible.value=true
    nextTick(()=>{
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
  // 表单提交
  const submitForm = async () => {
    dialogloading.value=true
    const form = unref(ruleFormRef)
    if (!form) {
      dialogloading.value=false
      return
    }
    await form.validate((valid) => {
      if (valid) {
        if(dialogVisibletitle.value==='新增用户'){
          dataGeneration(ruleForm)
        }else if(dialogVisibletitle.value==='修改用户'){
          let ruleFormjson={
            userId:id.value,
            ...ruleForm
          }
          dataGeneration(ruleFormjson,'/admin/sys-user/update')
        }
      }else{
        dialogloading.value=false
      }
    })
  }
  // 数据生成
  let dataGeneration=((json,url='/admin/sys-user/add')=>{
    httpToken({
      method: "post",
      url: url,
      data: json,
    }).then((res) => {
      dialogloading.value=false
      dialogVisible.value=false
      emit('Emit')
    }).catch(()=>{
      dialogloading.value=false
    })
  })
  defineExpose({
    add,
    editclick,
  });
</script>